<template>
  <div class="page">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('navSideForm') }}</h1>
        <!--
        <form id="search"><search-input-gcr name="query" v-model="searchQuery" :placeholder="$t('projectSearch')" /></form>
        -->
      </div>
    </div>
    <div class="page__body row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
        <h2>{{ $t('formListTitleHouse') }}</h2>
        <h3>{{ $t('formListSubtitleRegister') }}</h3>
        <p>{{ $t('formListIntroHouse') }}</p>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Registration-Form-fees-GCR-F031')">
              <td class="valign-center">
                Registration Form – Single-family or multifamily building
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Release-of-guarantee-GCR-F275')">
              <td class="valign-center">
                Release of guarantee – 24 months after end of work form
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Registration-Cancellation-Form-GCR-F251')">
              <td class="valign-center">
                Registration cancellation form
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuyerContract') }}</h3>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr
              v-on:click="
                openLink('https://www.garantiegcr.com/zone/formulaire/Garantee-Contract-Undivided-Co-ownership-GCR-C011')
              "
            >
              <td class="valign-center">
                Guarantee contract – Buildings not held in divided co-ownership
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="
                openLink('https://www.garantiegcr.com/zone/formulaire/Preliminary-Contract-Undivided-Co-Ownership-GCR-C071')
              "
            >
              <td class="valign-center">
                Preliminary contract – House or building (immovable) not held in divided co-ownership
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Business-Contract-Undivided-GCR-C091')">
              <td class="valign-center">
                Business contract
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/advance-payment-GCR-F051')">
              <td class="valign-center">
                Advance payment
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Annex-Preliminary-Contract-Enterprise-GCR-C061')"
            >
              <td class="valign-center">
                Annex to the preliminary contract or the contract of enterprise – Inclusions/exclusions/modifications
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Annex-Preliminary-Contract-Enterprise-GCR-C191')"
            >
              <td class="valign-center">
                Annex to the preliminary contract or the contract of enterprise – other conditions
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Pre-acceptance-inspection-GCR-F131')">
              <td class="valign-center">
                Pre-acceptance inspection form - Private portion
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuilding') }}</h3>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/End-of-Work-GCR-F101')">
              <td class="valign-center">
                End of work notification – Private portion
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Statement-Final-Performance-Work-GCR-F121')">
              <td class="valign-center">
                Statement of final performance of the work
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h2>{{ $t('formListTitleCondo') }}</h2>
        <h3>{{ $t('formListSubtitleRegister') }}</h3>

        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Registration-Form-Divided-Co-ownership-GCR-F041')"
            >
              <td class="valign-center">
                Registration form – Building held in divided co-ownership
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Registration-Cancellation-Form-GCR-F251')">
              <td class="valign-center">
                Registration cancellation form
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuyersPrivateParties') }}</h3>

        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Garantee-Contract-Divided-Co-ownership-GCR-C021')"
            >
              <td class="valign-center">
                Guarantee contract – Buildings held in divided co-ownership
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Preliminary-Contract-GCR-C081')">
              <td class="valign-center">
                Preliminary contract – Residential divided co-ownership
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Annex-Preliminary-Contract-GCR-C181')">
              <td class="valign-center">
                Annex to the preliminary contract – Residential divided co-ownership – Parking space(s) and storage locker(s)
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Annex-Preliminary-Contract-GCR-C201')">
              <td class="valign-center">
                Annex to the preliminary contract – Residential divided co-ownership – General coordinates of the co-ownership
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Annex-Preliminary-Contract-Enterprise-GCR-C061')"
            >
              <td class="valign-center">
                Annex to the preliminary contract or the contract of enterprise –Inclusions/exclusions/modifications
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Annex-Preliminary-Contract-Enterprise-GCR-C191')"
            >
              <td class="valign-center">
                Annex to the preliminary contract or the contract of enterprise – other conditions
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/advance-payment-GCR-F051')">
              <td class="valign-center">
                Advance payment
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Pre-acceptance-inspection-GCR-F131')">
              <td class="valign-center">
                Pre-acceptance inspection form – Private portion
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/End-of-Work-GCR-F101')">
              <td class="valign-center">
                End of work notification – Private portion
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuildingCommonAreas') }}</h3>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Pre-acceptance-form-GCR-F141')">
              <td class="valign-center">
                Pre-acceptance form – Building held in divided co-ownership – Common areas
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/End-Of-Work-Notification-co-ownership-GCR-F111')"
            >
              <td class="valign-center">
                End of work notification – Common areas
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr
              v-on:click="
                openLink('https://www.garantiegcr.com/zone/formulaire/Building-sheet-declaration-of-compliance-GCR-F601')
              "
            >
              <td class="valign-center">
                Building sheet/Declaration of compliance
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Statement-Final-Performance-Work-GCR-F121')">
              <td class="valign-center">
                Statement of final performance of the work
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h2>{{ $t('formListTitlePaperFormOrders') }}</h2>

        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/Purchase-Order-Promotional-Items')">
              <td class="valign-center">
                Purchase order – Promotional items
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/zone/formulaire/form-order')">
              <td class="valign-center">
                Form Order <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <div class="row v-formlist__images">
          <div class="col-6 col-xs-6 col-sm-4">
            <img src="../assets/gcr-affichette-de-chantier.jpg" width="500" height="500" class="v-formlist__images-image" />
            <h3 class="v-formlist__images-title">{{ $t('formListConstructionSitePosterSign') }}</h3>
            <p class="v-formlist__images-text">{{ $t('formListConstructionSitePosterSignSize') }}</p>
          </div>
          <div class="col-6 col-xs-6 col-sm-4">
            <img src="../assets/gcr-autocollant-vf.jpg" width="500" height="500" class="v-formlist__images-image" />
            <h3 class="v-formlist__images-title">{{ $t('formListSticker') }}</h3>
            <p class="v-formlist__images-text">{{ $t('formListStickerSize') }}</p>
          </div>
          <div class="col-6 col-xs-6 col-sm-4">
            <img src="../assets/gcr-drapeaux-petit-format.jpg" width="500" height="500" class="v-formlist__images-image" />
            <h3 class="v-formlist__images-title">{{ $t('formListFlag') }}</h3>
            <p class="v-formlist__images-text">{{ $t('formListFlagSize') }}</p>
          </div>
        </div>

        <!-- // -->
      </div>
    </div>
  </div>
</template>

<script>
import activeAccreditation from '@/components/mixins/active-accreditation'
import i18n from '@/i18n'

require('../utilities/page')
require('../utilities/table')
require('../utilities/button')

export default {
  components: {},
  mixins: [activeAccreditation],
  methods: {
    openLink: function(link) {
      if (link.startsWith('/')) {
        this.$router.push(link)
      } else {
        //window.location.href = link
        window.open(link)
      }
    }
  },
  computed: {
    isEnglish() {
      return i18n.locale === 'en'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: none;
  }
}

.v-formlist {
  &__table {
    margin: 15px 0;
    tr {
      background-color: gray('white');
    }
  }
  &__language {
    font-style: italic;
  }
  &__images {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    &-image {
      border: 1px solid gray('gray-150');
      max-width: 100%;
      height: auto;
    }
    &-title {
      margin-top: 15px;
      margin-bottom: 0;
    }
    &-text {
      font-size: 14px;
    }
  }
}
</style>
